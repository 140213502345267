import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";

import axiosClient from "../../axios-client";
import Miniwidget from "./Miniwidget";
import RevenueSurplace from "./revenue_surplace";
import RevenueEnligne from "./revenue_enligne";
import RevenueTotal from "./revenue_total";
import BarChart from "./barchart";
import { useStateContext } from "../../context/ContextProvider";

const Dashboard = (props) => {
  const { userRole } = useStateContext();
  const [totalCars, setTotalCars] = useState(null);
  const [totalSurplace, setTotalSurplace] = useState(null);
  const [totalEnligne, setTotalEnligne] = useState(null);
  const [totalReviews, setTotalReviews] = useState(null);

  useEffect(() => {
    const fetchTotalCars = async () => {
      try {
        const response = await axiosClient.get("/cars/total_cars/");
        setTotalCars(response.data.total_cars);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du total des voitures",
          error
        );
      }
    };

    const fetchTotalSurplace = async () => {
      try {
        const response = await axiosClient.get(
          "/booking/total_booking_surplace/"
        );
        setTotalSurplace(response.data.total_booking_surplace);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du total des réservations sur place",
          error
        );
      }
    };

    const fetchTotalEnligne = async () => {
      try {
        const response = await axiosClient.get(
          "/booking/total_reviews_enligne/"
        );
        setTotalEnligne(response.data.total_reviews_enligne);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du total des réservations en ligne",
          error
        );
      }
    };

    const fetchTotalReviews = async () => {
      try {
        const response = await axiosClient.get("/reviews/total_reviews/");
        setTotalReviews(response.data.total_reviews);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du total des témoignages",
          error
        );
      }
    };

    fetchTotalCars();
    fetchTotalSurplace();
    fetchTotalEnligne();
    fetchTotalReviews();
  }, []);

  const reports = [
    {
      title: "Voitures",
      iconClass: "car-side",
      total: totalCars !== null ? totalCars.toLocaleString() : "Chargement...",
    },
    {
      title: "Rés en ligne",
      iconClass: "credit-card",
      total:
        totalEnligne !== null ? totalEnligne.toLocaleString() : "Chargement...",
    },
    {
      title: "Rés Sur Place",
      iconClass: "cash",
      total:
        totalSurplace !== null
          ? totalSurplace.toLocaleString()
          : "Chargement...",
    },
    {
      title: "Témoignages",
      iconClass: "chat-processing",
      total:
        totalReviews !== null ? totalReviews.toLocaleString() : "Chargement...",
    },
  ];

  const handleClick = (lien) => {
    window.location.href = `${lien}`;
  };

  const handleMouseEnter = (event) => {
    // Modifier la propriété transform lors du survol de la souris
    event.currentTarget.style.transform = "translateY(-5px)";
  };

  const handleMouseLeave = (event) => {
    // Remettre à zéro la propriété transform lorsque la souris quitte
    event.currentTarget.style.transform = "translateY(0)";
  };

  return (
    <React.Fragment>
      <Miniwidget reports={reports} />
      {userRole === "SUPERADMIN" ? (
        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <RevenueSurplace />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <RevenueEnligne />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <RevenueTotal />
          </Col>
        </Row>
      ) : null}

      {userRole === "SUPERADMIN" ? (
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Statistiques des revenues</h4>
                <BarChart />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
    </React.Fragment>
  );
};

export default Dashboard;
