import { Route, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useStateContext } from '../../context/ContextProvider';


const ProtectedRoute = ({ component: Component, ...rest }) => {
  // const isAuthenticated = localStorage.getItem('firstName') && localStorage.getItem('lastName');
  const {token} = useStateContext()


  useEffect(() => {
  }, [token]);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Navigate to="/" />
        )
      }
    />
  );
};

export default ProtectedRoute;
