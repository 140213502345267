import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"; // Import Modal components
import axiosClient from "../../axios-client";
import parametres from "../../assets/images/parametres.png";
import { useNavigate } from "react-router-dom";

const ListManager = (props) => {
  const navigate = useNavigate();
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null); // State for selected user
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [managerToDelete, setManagerToDelete] = useState(null);

  useEffect(() => {
    // Faites une requête API pour récupérer les managers depuis Django backend
    axiosClient
      .get("/managers/")
      .then((response) => {
        setManagers(response.data); // Mettez à jour le state avec les managers récupérés
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des utilisateurs :",
          error
        );
      });
  }, []);
  

  const actions = (manager) => (
    <div>
      <button
        className="btn btn-sm  mr-2"
        style={{ backgroundColor: "#00BFB1 ", color: "white" }}
        onClick={() => {
          navigate(`/updateManager/${manager.id}`);
        }}
      >
        <i className="ion ion-md-create"></i> Modifier
      </button>{" "}
      <button
        className="btn btn-sm  mr-2"
        style={{ backgroundColor: "#00367F", color: "white" }}
        onClick={() => {
          setSelectedManager(manager);
          setModalOpen(true);
        }}
      >
        {" "}
        <i className="ion ion-md-eye"></i> Visualiser
      </button>
      <button
        className="btn btn-sm btn-danger mr-2"
        style={{ backgroundColor: "#E21818 ", color: "white" }}
        onClick={() => {
          setManagerToDelete(manager);
          setDeleteConfirmationOpen(true);
        }}
      >
        <i className="ion ion-ios-trash"></i> Supprimer
      </button>{" "}
    </div>
  );
  const superadminIdentifier = "superadmin@example.com"; // Change this to match how you identify the superadmin user

  // Transform users data to include JSX components as needed
  const transformedData = managers
    .filter((manager) => manager.email !== superadminIdentifier)
    .map((manager) => {
      return {
        ...manager,
        image: (
          <div
            className="avatar"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <img
              src={manager.image || parametres} // Use a default image URL if manager.image is falsy
              alt={`Avatar of ${manager.first_name} ${manager.last_name}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
        ),
        action: actions(manager), // Pass the user object to the actions function
      };
    });

  const handleAddManager = () => {
    navigate("/addmanager");
  };

  const data = {
    columns: [
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Prénom",
        field: "first_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Nom",
        field: "last_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Role",
        field: "role",
        sort: "asc",
        width: 150,
      },

      {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: transformedData, // Utilisez les données des utilisateurs transformées ici
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Liste des administarteurs</title>
      </MetaTags>

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Liste des administarteurs</CardTitle>
              <div className="mb-2" style={{ textAlign: "right" }}>
                <button
                  className="btn  ml-auto"
                  style={{ backgroundColor: "#00367F", color: "white" }}
                  onClick={handleAddManager}
                >
                  Ajouter administrateur
                </button>
              </div>

              <MDBDataTable responsive bordered data={data} />
              <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
                <ModalHeader
                  style={{ backgroundColor: "#00367F", color: "white" }}
                  toggle={() => setModalOpen(false)}
                >
                  {" "}
                  Information des administarteurs{" "}
                </ModalHeader>
                <ModalBody>
                  {selectedManager && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={selectedManager.image}
                            alt={`Avatar of ${selectedManager.first_name} ${selectedManager.last_name}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                          {selectedManager.first_name}{" "}
                          {selectedManager.last_name}
                        </p>
                      </div>
                      <p style={{ fontWeight: "bold" }}>
                        Email :{" "}
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedManager.email}
                        </span>
                      </p>
                      <p style={{ fontWeight: "bold" }}>
                        Role :{" "}
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {" "}
                          {selectedManager.role}
                        </span>
                      </p>
                      {/* Display other user information */}
                    </div>
                  )}
                </ModalBody>
              </Modal>
              <Modal
                isOpen={deleteConfirmationOpen}
                toggle={() => setDeleteConfirmationOpen(false)}
              >
                <ModalHeader toggle={() => setDeleteConfirmationOpen(false)}>
                  Confirmation
                </ModalHeader>
                <ModalBody>
                  {managerToDelete && (
                    <div>
                      <p>
                        Êtes-vous sûr de vouloir supprimer cette administrateur?
                      </p>
                      <button
                        className="btn btn-danger mr-2"
                        onClick={async () => {
                          try {
                            await axiosClient.delete(
                              `/managers/${managerToDelete.id}/`
                            ); // Adjust the endpoint as needed

                            setManagers(
                              managers.filter(
                                (manager) => manager.id !== managerToDelete.id
                              )
                            );

                            setDeleteConfirmationOpen(false);
                          } catch (error) {
                            console.error("Error deleting manager:", error);
                          }
                        }}
                      >
                        {" "}
                        Supprimer{" "}
                      </button>{" "}
                      <button
                        className="btn btn-secondary"
                        onClick={() => setDeleteConfirmationOpen(false)}
                      >
                        {" "}
                        Annuler{" "}
                      </button>
                    </div>
                  )}
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ListManager;
