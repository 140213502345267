import React, { useEffect, useState } from "react"
import axiosClient from "../../axios-client";
import ReactApexChart from 'react-apexcharts'

const BarChart = () => {

  const [filterByMonth, setFilterByMonth] = useState(true);
  const [year, setSelectedYear] = useState(new Date().getFullYear());

  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '25%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [] // Les catégories seront mises à jour dynamiquement
      },
      yaxis: {
        title: {
          text: 'Frais en DH'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " DH "
          }
        }
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let responseSurplace;
        let responseEnligne;
        let responseAll;

        if (filterByMonth) {
          // Appelez l'URL de votre vue Django pour obtenir les données
          responseSurplace = await axiosClient.get("/booking/total_frais_surplace_by_month/", {
            params: {
              year: year,
            },
          });
          responseEnligne = await axiosClient.get("/booking/total_frais_enligne_by_month/", {
            params: {
              year: year,
            },
          });
          responseAll = await axiosClient.get("/booking/total_frais_all_by_month/", {
            params: {
              year: year,
            },
          });
        } else {
          // Appelez l'URL de votre vue Django pour obtenir les données
          responseSurplace = await axiosClient.get("/booking/total_frais_surplace_by_year/");
          responseEnligne = await axiosClient.get("/booking/total_frais_enligne_by_year/");
          responseAll = await axiosClient.get("/booking/total_frais_all_by_year/");
        }


        // Mettez à jour l'état avec les données reçues
        setState((prevState) => ({
          ...prevState,
          series: [
            {
              name: 'Frais Sur Place',
              data: responseSurplace.data.map((entry) => entry.total_frais_surplace),
            },
            {
              name: 'Frais Enligne',
              data: responseEnligne.data.map((entry) => entry.total_frais_enligne),
            },
            {
              name: 'Frais All',
              data: responseAll.data.map((entry) => entry.total_frais_all),
            },
          ],
          options: {
            ...prevState.options,
            xaxis: {
              categories: filterByMonth
                ? responseSurplace.data.map((entry) => entry.month)
                : responseSurplace.data.map((entry) => entry.year),
            },
          },
        }));
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
        // Gérer l'erreur selon vos besoins
      }
    };

    fetchData();
  }, [filterByMonth, year]);

  return (
    <div >

      <div className="d-flex flex-row gap-2 justify-content-center">

        <div className="btn-group d-flex flex-row  justify-content-center" role="group" aria-label="Basic radio toggle button group">
          <input
            type="radio"
            className="btn-check"
            id="btnradio1"
            value="month"
            checked={filterByMonth}
            onChange={() => setFilterByMonth(true)}
            autoComplete="off"
          />
          <label className="btn btn-primary rounded-left" htmlFor="btnradio1">filtrer par mois</label>

          <input
            type="radio"
            id="btnradio2"
            className="btn-check"
            value="year"
            checked={!filterByMonth} // Notez le changement ici
            onChange={() => setFilterByMonth(false)}
            autoComplete="off"
          />
          <label className="btn btn-primary rounded-right" htmlFor="btnradio2">filtrer par années</label>
        </div>


      </div>
      <div className="d-flex flex-row gap-2 justify-content-center w-100">
        {filterByMonth && (
          <select
            className="form-select"
            style={{
              width: 150
            }}
            value={year}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
          >
            <option value={new Date().getFullYear() - 1}>2023</option>
            <option value={new Date().getFullYear()}>2024</option>
            <option value={new Date().getFullYear() + 1}>2025</option>
          </select>
        )}
      </div>

      {state.options.xaxis.categories && (
        <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
      )}
    </div>
  );
}

export default BarChart
