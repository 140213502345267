import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  UncontrolledAlert,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axiosClient from "../../axios-client";

function Riviews() {
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standardM, setmodal_standardM] = useState(false);
  const [LieuToDelete, setLieuToDelete] = useState(null);
  const [tarifLieu, setTarifLieu] = useState();
  const [nameLieu, setNameLieu] = useState("");
  const [lieuId, setLieuId] = useState();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [showAlertS, setShowAlertS] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertM, setShowAlertM] = useState(false);

  const showSuccessAlert = () => {
    setShowAlert(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const showSuccessAlertS = () => {
    setShowAlertS(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertS(false);
    }, 5000);
  };

  const showSuccessAlertM = () => {
    setShowAlertM(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertM(false);
    }, 5000);
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standardM() {
    setmodal_standardM(!modal_standardM);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const renderActions = (Lieu) => (
    <div>
      <button
        className="btn btn-sm btn-warning mr-2"
        onClick={() => handleEditPickup(Lieu)}
      >
        <i className="ion ion-md-create mr-1"></i> Modifier
      </button>{" "}
      <button
        className="btn btn-sm btn-danger mr-2"
        onClick={() => {
          setLieuToDelete(Lieu.id_pickup);
          setDeleteConfirmationOpen(true);
        }}
      >
        <i className="ion ion-ios-trash mr-1"></i> Supprimer
      </button>
    </div>
  );

  const [lieuxData, setLieuxData] = useState({
    columns: [
      {
        label: "ID",
        field: "id_pickup",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lieux",
        field: "lieu",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tarif",
        field: "tarif",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 50,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get("/pickup-point/");
        const lieux = response.data;

        const rows = await Promise.all(
          lieux.map(async (lieu) => {
            return {
              id_pickup: lieu.id_pickup,
              lieu: lieu.lieu,
              tarif: lieu.tarif,
              actions: renderActions(lieu),
            };
          })
        );

        setLieuxData((prevData) => ({
          ...prevData,
          rows,
        }));
      } catch (error) {
        console.error("Error fetching reviews :", error);
      }
    };

    fetchData();
  }, []);

  const handleDeletePickup = async () => {
    try {
      await axiosClient.delete(`/pickup-point/${LieuToDelete}/`);

      setLieuxData((prevData) => ({
        ...prevData,
        rows: prevData.rows.filter((Lieu) => Lieu.id_pickup !== LieuToDelete),
      }));
      setDeleteConfirmationOpen(false);

      showSuccessAlertS(); // Montrer l'alerte de suppression réussie
    } catch (error) {
      console.error("Error deleting lieu:", error);
    }
  };

  const handleSavePickup = async () => {
    const formData = new FormData();
    formData.append("lieu", nameLieu);
    formData.append("tarif", tarifLieu);

    try {
      const response = await axiosClient.post("/pickup-point/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Ajouter le nouveau lieu à la liste existante
      const newLieu = {
        id_pickup: response.data.id_pickup,
        lieu: nameLieu,
        tarif: tarifLieu,
        actions: renderActions(response.data),
      };

      setLieuxData((prevData) => ({
        ...prevData,
        rows: [...prevData.rows, newLieu],
      }));

      tog_standard();
      showSuccessAlert();
    } catch (error) {
      console.error("Error saving lieu:", error);
    }
  };

  const handleEditPickup = (lieu) => {
    setLieuId(lieu.id_pickup);
    setNameLieu(lieu.lieu);
    setTarifLieu(lieu.tarif);

    setmodal_standardM(true);
  };

  const handleModifyPickup = async () => {
    const formData = new FormData();
    formData.append("lieu", nameLieu);
    formData.append("tarif", tarifLieu);

    try {
      const response = await axiosClient.put(
        `/pickup-point/${lieuId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Mettre à jour les données du lieu modifié dans l'état local
      const modifiedLieuIndex = lieuxData.rows.findIndex(
        (lieu) => lieu.id_pickup === lieuId
      );

      if (modifiedLieuIndex !== -1) {
        const updatedRows = [...lieuxData.rows];
        updatedRows[modifiedLieuIndex] = {
          id_pickup: lieuId,
          lieu: nameLieu,
          tarif: tarifLieu,
          actions: renderActions(response.data),
        };

        setLieuxData((prevData) => ({
          ...prevData,
          rows: updatedRows,
        }));
      }

      tog_standardM();
      showSuccessAlertM();
    } catch (error) {
      console.error("Error saving lieu:", error);
    }
  };

  const resetForm = () => {
    setLieuId();
    setNameLieu("");
    setTarifLieu();
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed"
        style={{ top: "10px", right: "10px", zIndex: "9999" }}
      >
        {showAlert && (
          <UncontrolledAlert color="success">
            <strong>Ajout du point de relais réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertS && (
          <UncontrolledAlert color="success">
            <strong>Suppresion du point de relais réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertM && (
          <UncontrolledAlert color="success">
            <strong>Modification du point de relais réussi !!</strong>
          </UncontrolledAlert>
        )}
      </div>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle style={{ fontSize: 20 }} className="h4">
                Point de relais{" "}
              </CardTitle>
              <MDBDataTable responsive striped bordered data={lieuxData} />
              <div className="text-left">
                <button
                  type="button"
                  onClick={() => tog_standard()}
                  className="btn btn-primary waves-effect waves-light mr-3"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Ajouter point de relais
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal_standard} toggle={() => tog_standard()}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Ajouter un point de relais
          </h5>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label htmlFor="categoryTitle">
                <span style={{ color: "black", fontWeight: "normal" }}>
                  Lieu
                </span>
              </label>
              <input
                type="text"
                className="form-control"
                id="nameLieu"
                value={nameLieu}
                onChange={(e) => setNameLieu(e.target.value)}
                placeholder="nom du lieu..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarif
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifLieu"
                value={tarifLieu}
                onChange={(e) => setTarifLieu(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
              resetForm();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleSavePickup()}
          >
            Enregistrer
          </button>
        </div>
      </Modal>

      <Modal isOpen={modal_standardM} toggle={() => tog_standardM()}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Modifier point de relais
          </h5>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>Lieu</span>
              <input
                type="text"
                className="form-control"
                id="nameLieu"
                value={nameLieu}
                onChange={(e) => setNameLieu(e.target.value)}
                placeholder="nom du lieu..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarif
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifLieu"
                value={tarifLieu}
                onChange={(e) => setTarifLieu(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standardM();
              resetForm();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleModifyPickup()}
          >
            Modifier
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={deleteConfirmationOpen}
        toggle={() => setDeleteConfirmationOpen(false)}
      >
        <ModalHeader toggle={() => setDeleteConfirmationOpen(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>
          {LieuToDelete && (
            <div>
              <p>Est ce que vous étes sur de supprimer ce lieu ?</p>
              <button
                className="btn btn-danger mr-2"
                onClick={handleDeletePickup}
              >
                {" "}
                Supprimer{" "}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setDeleteConfirmationOpen(false)}
              >
                {" "}
                Annuler{" "}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Riviews;
