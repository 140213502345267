
import React, { useEffect } from 'react';

function TestPage() {
  useEffect(() => {
    console.log('TestPage component mounted');
    
    // You can add more test messages or code here
  }, []);
  return (
    <div>
      <h1>Welcome to the Test Page</h1>
      <p>This is a simple React test page.</p>
      <p>You can add your test content here.</p>
    </div>
  );
}

export default TestPage;
