import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Badge,
  UncontrolledAlert,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axiosClient from "../../axios-client";
import oeil from "../../assets/images/oeil.png";

function Contacts() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [showAlertS, setShowAlertS] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertM, setShowAlertM] = useState(false);

  const showSuccessAlert = () => {
    setShowAlert(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const showSuccessAlertM = () => {
    setShowAlertM(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertM(false);
    }, 5000);
  };

  const showSuccessAlertS = () => {
    setShowAlertS(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertS(false);
    }, 5000);
  };

  const renderActions = (Contact) => (
    <div>
      <button
        className="btn btn-sm  mr-2"
        style={{ backgroundColor: "#00367F", color: "white" }}
        onClick={() => {
          setSelectedContact(Contact);
          setModalOpen(true);
        }}
      >
        <img
          src={oeil}
          alt="visualiser"
          style={{ width: "20px", height: "20px" }}
        />{" "}
        Voir message
      </button>{" "}
      <button
        className="btn btn-sm btn-danger mr-2"
        onClick={() => {
          setContactToDelete(Contact.id);
          setDeleteConfirmationOpen(true);
        }}
      >
        <i className="ion ion-ios-trash mr-1"></i> Supprimer
      </button>{" "}
      <button
        className="btn btn-sm  mr-2"
        style={{ backgroundColor: "#00BFB1 ", color: "white" }}
        onClick={() => {
          handleMarkAsResolved(Contact.id);
        }}
      >
        <i className="ion ion-md-checkmark"></i> Marquer comme résolu
      </button>
    </div>
  );

  const [ContactsData, setContactsData] = useState({
    columns: [
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Nom complet",
        field: "nom",
        sort: "asc",
        width: 50,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date_contact",
        sort: "asc",
        width: 50,
      },
      {
        label: "Etat",
        field: "is_check",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 50,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const formatDateTime = (timestamp) => {
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        return new Date(timestamp).toLocaleString("fr-FR", options);
      };

      try {
        const response = await axiosClient.get("/contacts/");
        const contactsData = response.data;

        // Sort contactsData by date_contact in descending order
        contactsData.sort(
          (a, b) => new Date(b.date_contact) - new Date(a.date_contact)
        );

        const rows = await Promise.all(
          contactsData.map(async (contact) => {
            const is_check = contact.is_check ? (
              <Badge
                className={"rounded-pill bg-success"}
                style={{ fontSize: 15 }}
              >
                Regler
              </Badge>
            ) : (
              <Badge
                className={"rounded-pill bg-danger"}
                style={{ fontSize: 15 }}
              >
                Non regler
              </Badge>
            );
            return {
              id: contact.id,
              nom: contact.nom,
              email: contact.email,
              date_contact: formatDateTime(contact.date_contact),
              is_check: is_check,
              actions: renderActions(contact),
            };
          })
        );

        setContactsData((prevData) => ({
          ...prevData,
          rows,
        }));
      } catch (error) {
        console.error("Error fetching reviews :", error);
      }
    };

    fetchData();
  }, []);

  const handleMarkAsResolved = async (contactId) => {
    try {
      // Fetch the current state of the ticket
      const response = await axiosClient.get(`/contacts/${contactId}/`);
      const currentContact = response.data;

      // Check if the ticket is already resolved
      if (currentContact.is_check) {
        console.log(`Contact ${contactId} is already resolved.`);
        showSuccessAlertM();
        return;
      }

      // If not resolved, then make the update
      await axiosClient.put(`contacts/update-check/${contactId}/`);

      const is_check = (
        <Badge className={"rounded-pill bg-success"} style={{ fontSize: 15 }}>
          Regler
        </Badge>
      );

      // Mettez à jour l'état local des tickets après la mise à jour réussie
      setContactsData((prevData) => ({
        ...prevData,
        rows: prevData.rows.map((row) =>
          row.nom === currentContact.nom ? { ...row, is_check: is_check } : row
        ),
      }));

      showSuccessAlert();

      console.log(`Contact ${contactId} marqué comme résolu.`);
    } catch (error) {
      console.error(
        `Erreur lors de la mise à jour du contact ${contactId}:`,
        error
      );
    }
  };

  const handleDeleteContact = async () => {
    try {
      await axiosClient.delete(`/contacts/${contactToDelete}/`);

      setContactsData((prevData) => ({
        ...prevData,
        rows: prevData.rows.filter((contact) => contact.id !== contactToDelete),
      }));
      setDeleteConfirmationOpen(false);

      showSuccessAlertS(); // Montrer l'alerte de suppression réussie
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed"
        style={{ top: "10px", right: "10px", zIndex: "9999" }}
      >
        {showAlertS && (
          <UncontrolledAlert color="success">
            <strong>Suppresion du témoignage réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlert && (
          <UncontrolledAlert color="success">
            <strong>Marquer ce ticket comme régler réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertM && (
          <UncontrolledAlert color="warning">
            <strong>Ce ticket est deja marquer comme régler !!</strong>
          </UncontrolledAlert>
        )}
      </div>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle style={{ fontSize: 20 }} className="h4">
                Contacts :{" "}
              </CardTitle>
              <MDBDataTable responsive striped bordered data={ContactsData} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
        <ModalHeader
          style={{ backgroundColor: "#008080", color: "white" }}
          toggle={() => setModalOpen(false)}
        >
          {" "}
          Contact
        </ModalHeader>
        <ModalBody>
          {selectedContact && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                {/* <h3> : {selectedTicket.code}</h3> */}
              </div>
              <p style={{ fontWeight: "bold" }}>
                Email :{" "}
                <span style={{ color: "black", fontWeight: "normal" }}>
                  {selectedContact.email}
                </span>
              </p>
              <p style={{ fontWeight: "bold" }}>
                Message :{" "}
                <span style={{ color: "black", fontWeight: "normal" }}>
                  {selectedContact.comment}
                </span>
              </p>
              {/* Display other user information */}
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteConfirmationOpen}
        toggle={() => setDeleteConfirmationOpen(false)}
      >
        <ModalHeader toggle={() => setDeleteConfirmationOpen(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>
          {contactToDelete && (
            <div>
              <p>Est ce que vous étes sur de supprimer ce contact ?</p>
              <button
                className="btn btn-danger mr-2"
                onClick={handleDeleteContact}
              >
                {" "}
                Supprimer{" "}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setDeleteConfirmationOpen(false)}
              >
                {" "}
                Annuler{" "}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Contacts;
