import React, { useState } from "react";
import Routes from "./routes"
import { useLocation, useNavigate } from "react-router-dom";

import "./App.css"
import { useStateContext } from "./context/ContextProvider";

function App() {

    const { user, userRole, setToken, setUser } = useStateContext()
    const location = useLocation();
    const isLoginPage = location.pathname === "/";
    const navigate = useNavigate();

    const logout = () => {
        // Clear user session data from localStorage
        setUser({})
        setToken(null)

        // Redirect to the login page
        window.location.href = '/';
    };

    const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")

    const changeStyle = () => {
        if (style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled")
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    }

    const changeStyle1 = () => {
        if (style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion") {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled")
        }
        else {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    }

    return (
        <div id="wrapper">
            {isLoginPage ? null : (

                <ul className={style} id="accordionSidebar">

                    <div className="sidebar-brand d-flex align-items-center justify-content-center">
                        <div className="sidebar-brand-icon">
                            Cars
                        </div>
                        <div className="sidebar-brand-text mx-2">management</div>
                    </div>

                    <hr className="sidebar-divider my-0" />

                    <li className="nav-item active">
                        <div className="nav-link" onClick={() => navigate('/dashboard')}>
                            <i className="fas fa-fw fa-tachometer-alt" style={{ cursor: 'pointer' }}></i>
                            <span style={{ cursor: 'pointer' }}>Tableau de bord</span>
                        </div>
                    </li>

                    <hr className="sidebar-divider" />

                    <div className="sidebar-heading">
                        Interface
                    </div>

                    {userRole === 'SUPERADMIN' && (
                        <li className="nav-item">
                            <div className="nav-link" onClick={() => navigate('/listManagers')}>
                                <i className="fas fa-fw fa-wrench" style={{ cursor: 'pointer' }}></i>
                                <span style={{ cursor: 'pointer' }}>Administrateurs</span>
                            </div>
                        </li>
                    )}

                    <li class="nav-item">
                        <div class="nav-link" onClick={() => navigate('/carsList')}>
                            <i class="fas fa-fw fa-car" style={{ cursor: 'pointer' }}></i>
                            <span style={{ cursor: 'pointer' }}>Voitures</span>
                        </div>
                    </li>

                    <li class="nav-item">
                        <div class="nav-link" onClick={() => navigate('/booking_enligne')}>
                            <i class="fas fa-fw fa-money-check-alt" style={{ cursor: 'pointer' }}></i>
                            <span style={{ cursor: 'pointer' }}>Réservations en ligne</span>
                        </div>
                    </li>

                    <li class="nav-item">
                        <div class="nav-link" onClick={() => navigate('/booking_surplace')}>
                            <i class="fas fa-fw fa-hand-holding-usd" style={{ cursor: 'pointer' }}></i>
                            <span style={{ cursor: 'pointer' }}>Réservations sur place</span>
                        </div>
                    </li>

                    {userRole === 'SUPERADMIN' && (
                        <li class="nav-item">
                            <div class="nav-link" onClick={() => navigate('/booking_all')}>
                                <i class="fas fa-fw fa-clipboard-list" style={{ cursor: 'pointer' }}></i>
                                <span style={{ cursor: 'pointer' }}>Historique réservations</span>
                            </div>
                        </li>
                    )}

                    <li class="nav-item">
                        <div class="nav-link" onClick={() => navigate('/pointRelais')}>
                            <i class="fas fa-fw fa-map-marker" style={{ cursor: 'pointer' }}></i>
                            <span style={{ cursor: 'pointer' }}>Point relais</span>
                        </div>
                    </li>

                    <li class="nav-item">
                        <div class="nav-link" onClick={() => navigate('/temoignages')}>
                            <i class="fas fa-fw fa-comments" style={{ cursor: 'pointer' }}></i>
                            <span style={{ cursor: 'pointer' }}>Témoignages</span>
                        </div>
                    </li>

                    <li class="nav-item">
                        <div class="nav-link" onClick={() => navigate('/contacts')}>
                            <i class="fas fa-fw fa-envelope-open-text" style={{ cursor: 'pointer' }}></i>
                            <span style={{ cursor: 'pointer' }}>Contacts</span>
                        </div>
                    </li>

                    {/* <li class="nav-item">
                        <a class="nav-link" href="/parametres">
                            <i class="fas fa-fw fa-cog"></i>
                            <span>Paramètres</span></a>
                    </li> */}

                    <hr className="sidebar-divider" />

                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
                    </div>
                </ul>
            )}
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    {isLoginPage ? null : (

                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={changeStyle1}>
                                <i className="fa fa-bars"></i>
                            </button>

                            <div
                                className="d-none d-sm-inline-block form-inline mr-auto ml-md-4 my-2 my-md-0 mw-100">
                                <span style={{ fontSize: "18px" }}>Australie Cars</span>
                            </div>

                            <ul className="navbar-nav ml-auto">

                                <div className="topbar-divider d-none d-sm-block"></div>

                                <li className="nav-item dropdown no-arrow">
                                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                            {user.first_name} {user.last_name}
                                        </span>
                                        <img className="img-profile rounded-circle"
                                            src="styles/img/undraw_profile.svg" />
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                        aria-labelledby="userDropdown">
                                        <div className="dropdown-item" onClick={() => navigate('/profile')}>
                                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Profile
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal" onClick={logout}>
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Déconnecter
                                        </a>
                                    </div>
                                </li>

                            </ul>

                        </nav>
                    )}
                    <div className="container-fluid">
                        <Routes role={userRole} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
