import React, { useEffect, useState } from 'react';
import { Row, Card, CardBody } from "reactstrap";
import axiosClient from "../../axios-client";

const RevenueTotal = () => {

    const [total, setTotal] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Récupérer les données des commandes
                const response = await axiosClient.get("/booking/total_revenue/");
                const paiementData = response.data.total_paiement

                setTotal(paiementData);

            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Total des revenues</h4>
                    <Row>
                        <div className='d-flex align-items-start justify-content-between'>
                            <div>
                                <h3>{total} DH</h3>
                            </div>
                            <i className="float-end mdi mdi-cash-register" style={{ fontSize: 55 }}></i>
                        </div>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default RevenueTotal;