import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Modal, UncontrolledAlert } from "reactstrap";
import axiosClient from "../../axios-client";
import defaultImage from "../../../src/assets/images/default-image.png";
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user } = useStateContext();
  const navigate = useNavigate();
  const [mFirstName, setMFirstName] = useState("");
  const [mLasttName, setMLasttName] = useState("");
  const [mEmail, setMEmail] = useState("");
  const [mTel, setMTel] = useState("");
  const [mGenre, setMGenre] = useState("");
  const [mAdresseVille, setMAdresseVille] = useState("");
  const [image, setImage] = useState();
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standardP, setmodal_standardP] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertP, setShowAlertP] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState();

  const showSuccessAlert = () => {
    setShowAlert(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const showSuccessAlertP = () => {
    setShowAlertP(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertP(false);
    }, 5000);
  };

  const handleChangeImage = (e) => {
    const image = e.target.files[0];
    setSelectedImage(image);
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standardP() {
    setmodal_standardP(!modal_standardP);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    if (selectedImage) {
      setImage(URL.createObjectURL(selectedImage));
    } else {
      setSelectedImage();
    }
  }, [selectedImage]);

  const handleEditManager = async () => {
    try {
      const formData = new FormData();

      formData.append("first_name", mFirstName);
      formData.append("last_name", mLasttName);
      formData.append("email", mEmail);
      formData.append("gender", mGenre);
      formData.append("tel", mTel);
      formData.append("ville", mAdresseVille);

      axiosClient.put(`/user_update/${user.id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      showSuccessAlert();
      tog_standard();
      navigate('/profile');
    } catch (error) {
      console.error("Error updating manager:", error);
    }
  };

  const saveImage = () => {
    const formData = new FormData();
    formData.append("image", selectedImage);
    axiosClient
      .put("/user_image_update/" + user.id + "/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setSelectedImage();
      });
  };

  const isPasswordValid = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
    const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
    const minLengthRegex = /^.{8,}$/;
    const digitRegex = /\d/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;

    let errors = [];

    if (!minLengthRegex.test(password)) {
      errors.push("Le mot de passe doit comporter au moins 8 caractères.");
    }

    if (!digitRegex.test(password)) {
      errors.push("Le mot de passe doit contenir au moins un chiffre.");
    }

    if (!lowercaseRegex.test(password)) {
      errors.push(
        "Le mot de passe doit contenir au moins une lettre minuscule."
      );
    }

    if (!uppercaseRegex.test(password)) {
      errors.push(
        "Le mot de passe doit contenir au moins une lettre majuscule."
      );
    }
    if (!specialchar.test(password)) {
      errors.push(
        "Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=)."
      );
    }
    if (password.length > 20) {
      errors.push("Le mot de passe ne doit pas dépasser 20 caractères.");
    }

    if (errors.length > 0) {
      setMessage(errors[0]);
      return false;
    }

    return passwordRegex.test(password);
  };

  const handleEditPassword = () => {
    if (!isPasswordValid(newPassword)) {
      return;
    }
    axiosClient
      .put(`/change_password/${user.id}/`, {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      })
      .then((response) => {
        if (response.data.success) {
          showSuccessAlertP();
          tog_standardP();
          navigate('/profile');
        } else {
          // toast.error('Erreur de modification', {
          //     position: toast.POSITION.TOP_CENTER
          // });
          setMessage(response.data.error);
        }
      });
  };

  const handleSetEditManager = (manager) => {
    setMFirstName(manager.first_name);
    setMLasttName(manager.last_name);
    setMEmail(manager.email);
    setMTel(manager.tel);
    setMAdresseVille(manager.ville);
    setMGenre(manager.gender);

    setmodal_standard(true);
  };

  const resetForm = () => {
    setMFirstName("");
    setMLasttName("");
    setMEmail("");
    setMTel("");
    setMGenre("");
    setMAdresseVille("");
  };

  const resetPassword = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setMessage("");
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed"
        style={{ top: "10px", right: "10px", zIndex: "9999" }}
      >
        {showAlert && (
          <UncontrolledAlert color="success">
            <strong>Modification du profile réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertP && (
          <UncontrolledAlert color="success">
            <strong>Modification du mot de passe réussi !!</strong>
          </UncontrolledAlert>
        )}
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              {/* -------------------------------------- */}
              <div className="d-flex flex-column flex-md-row gap-3 justify-content-center align-items-center">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      height: "200px",
                      width: "200px",
                    }}
                  >
                    <img
                      src={
                        image ? image : user.image ? user.image : defaultImage
                      }
                      alt="avatar"
                      className="rounded-circle img-fluid"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div>
                    {!selectedImage && (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary"
                        style={{
                          marginTop: "10px",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <span>Choisissez une photo</span>

                        <input
                          type="file"
                          name="mImage"
                          id="mImage"
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            opacity: 0,
                          }}
                          onChange={handleChangeImage}
                        />
                      </button>
                    )}
                    {selectedImage && (
                      <div className="d-flex flex-row justify-content-around">
                        <div style={{ marginLeft: 5 }}>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={saveImage}
                          >
                            <span style={{ fontSize: "12px" }}>
                              Enregistrer
                            </span>{" "}
                          </button>
                        </div>

                        <div style={{ marginLeft: 5 }}>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              setSelectedImage();
                              setImage();
                            }}
                          >
                            <span style={{ fontSize: "12px" }}>Annuler</span>{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-7 px-0 py-0 card">
                  <div className="card-body" style={{ height: "100%" }}>
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Nom complet</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {user.first_name} {user.last_name}
                        </p>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Email</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">{user.email}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Téléphone</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {user.tel ? (
                            user.tel
                          ) : (
                            <span
                              className=" mb-4"
                              style={{
                                color: "lightgray",
                              }}
                            >
                              Téléphone non spécifié
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Genre</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">{user.gender}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Adresse</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {!user.ville ? (
                            <p
                              className=" mb-4"
                              style={{
                                color: "lightgray",
                              }}
                            >
                              Ville non spécifiée
                            </p>
                          ) : (
                            <>
                              <p className="text-muted mb-1">{user.ville}</p>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2 text-end pb-2">
                      <button
                        type="button"
                        class="btn btn-md btn-outline-info px-5 mr-3"
                        onClick={() => {
                          tog_standardP();
                        }}
                        data-toggle="modalP"
                        data-target="#myModalP"
                      >
                        Changer mot de passe
                      </button>
                      <button
                        type="button"
                        class="btn btn-md btn-outline-success px-5"
                        onClick={() => {
                          tog_standard();
                          handleSetEditManager(user);
                        }}
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        Modifier
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal_standard} toggle={() => tog_standard()}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Modifier votre profile
          </h5>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label>Prénom</label>
              <input
                type="text"
                className="form-control"
                id="mFirstName"
                value={mFirstName}
                onChange={(e) => setMFirstName(e.target.value)}
                placeholder="Entrez votre prénom"
              />
            </div>
            <div className="form-group">
              <label>Nom</label>
              <input
                type="text"
                className="form-control"
                id="mLasttName"
                value={mLasttName}
                onChange={(e) => setMLasttName(e.target.value)}
                placeholder="Entrez votre nom"
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                id="mEmail"
                value={mEmail}
                onChange={(e) => setMEmail(e.target.value)}
                placeholder="Entrez votre email"
              />
            </div>
            <div className="form-group">
              <label>Tel</label>
              <input
                type="text"
                className="form-control"
                id="mTel"
                value={mTel}
                onChange={(e) => setMTel(e.target.value)}
                placeholder="Entrez votre numéro de téléphone"
              />
            </div>
            <div className="form-group">
              <label>Genre</label>
              <select
                className="form-control"
                id="mGenre"
                value={mGenre}
                onChange={(e) => setMGenre(e.target.value)}
                name="gender"
              >
                <option value="Femme">Femme</option>
                <option value="Homme">Homme</option>
              </select>
            </div>
            <div className="form-group">
              <label>Ville</label>
              <input
                type="text"
                className="form-control"
                id="mAdresseVille"
                value={mAdresseVille}
                onChange={(e) => setMAdresseVille(e.target.value)}
                placeholder="Entrez votre ville"
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
              resetForm();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleEditManager()}
          >
            Enregistrer
          </button>
        </div>
      </Modal>

      <Modal isOpen={modal_standardP} toggle={() => tog_standardP()}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Modifier mot de passe
          </h5>
        </div>
        <div className="modal-body">
          {message && (
            <div className="alert alert-danger py-2">
              <strong className="">Erreur! </strong>
              {" " + message}
            </div>
          )}
          <form>
            <div className="form-group">
              <label>Ancien mot de passe</label>
              <input
                type="password"
                className="form-control"
                id="oldPassword"
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Entrez lancien mot de passe"
              />
            </div>
            <div className="form-group">
              <label>Nouveau mot de passe</label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Entrez le nouveau mot de passe"
              />
            </div>
            <div className="form-group">
              <label>Confirmer mot de passe</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Entrez la comfirmation de mot de passe"
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standardP();
              resetPassword();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleEditPassword()}
          >
            Enregistrer
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Profile;
