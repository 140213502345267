import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  UncontrolledAlert,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axiosClient from "../../axios-client";
import oeil from "../../assets/images/oeil.png";

function Riviews() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [reviewToDelete, setReviewToDelete] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [showAlertS, setShowAlertS] = useState(false);

  const showSuccessAlertS = () => {
    setShowAlertS(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertS(false);
    }, 5000);
  };

  const renderActions = (Review) => (
    <div>
      <button
        className="btn btn-sm  mr-2"
        style={{ backgroundColor: "#00367F", color: "white" }}
        onClick={() => {
          setSelectedReview(Review);
          setModalOpen(true);
        }}
      >
        <img
          src={oeil}
          alt="visualiser"
          style={{ width: "20px", height: "20px" }}
        />{" "}
        Voir message
      </button>{" "}
      <button
        className="btn btn-sm btn-danger mr-2"
        onClick={() => {
          setReviewToDelete(Review.id);
          setDeleteConfirmationOpen(true);
        }}
      >
        <i className="ion ion-ios-trash mr-1"></i> Supprimer
      </button>
    </div>
  );

  const [ReviewsData, setReviewsData] = useState({
    columns: [
      {
        label: "Prénom",
        field: "first_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Nom",
        field: "last_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date_review",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 50,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const formatDateTime = (timestamp) => {
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        return new Date(timestamp).toLocaleString("fr-FR", options);
      };

      try {
        const response = await axiosClient.get("/reviews/");
        const reviewsData = response.data;

        // Sort reviewsData by date_review in descending order
        reviewsData.sort(
          (a, b) => new Date(b.date_review) - new Date(a.date_review)
        );

        const rows = await Promise.all(
          reviewsData.map(async (review) => {
            return {
              first_name: review.first_name,
              last_name: review.last_name,
              email: review.email,
              date_review: formatDateTime(review.date_review),
              actions: renderActions(review),
            };
          })
        );

        setReviewsData((prevData) => ({
          ...prevData,
          rows,
        }));
      } catch (error) {
        console.error("Error fetching reviews :", error);
      }
    };

    fetchData();
  }, []);

  const handleDeleteReview = async () => {
    try {
      await axiosClient.delete(`/reviews/${reviewToDelete}/`);

      setReviewsData((prevData) => ({
        ...prevData,
        rows: prevData.rows.filter((review) => review.id !== reviewToDelete),
      }));
      setDeleteConfirmationOpen(false);

      showSuccessAlertS(); // Montrer l'alerte de suppression réussie
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed"
        style={{ top: "10px", right: "10px", zIndex: "9999" }}
      >
        {showAlertS && (
          <UncontrolledAlert color="success">
            <strong>Suppresion du témoignage réussi !!</strong>
          </UncontrolledAlert>
        )}
      </div>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle style={{ fontSize: 20 }} className="h4">
                Témoignages :{" "}
              </CardTitle>
              <MDBDataTable responsive striped bordered data={ReviewsData} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
        <ModalHeader
          style={{ backgroundColor: "#008080", color: "white" }}
          toggle={() => setModalOpen(false)}
        >
          {" "}
          Témoignage
        </ModalHeader>
        <ModalBody>
          {selectedReview && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                {/* <h3> : {selectedTicket.code}</h3> */}
              </div>
              <p style={{ fontWeight: "bold" }}>
                Email :{" "}
                <span style={{ color: "black", fontWeight: "normal" }}>
                  {selectedReview.email}
                </span>
              </p>
              <p style={{ fontWeight: "bold" }}>
                Message :{" "}
                <span style={{ color: "black", fontWeight: "normal" }}>
                  {selectedReview.comment}
                </span>
              </p>
              {/* Display other user information */}
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteConfirmationOpen}
        toggle={() => setDeleteConfirmationOpen(false)}
      >
        <ModalHeader toggle={() => setDeleteConfirmationOpen(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>
          {reviewToDelete && (
            <div>
              <p>Est ce que vous étes sur de supprimer ce témoignage ?</p>
              <button
                className="btn btn-danger mr-2"
                onClick={handleDeleteReview}
              >
                {" "}
                Supprimer{" "}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setDeleteConfirmationOpen(false)}
              >
                {" "}
                Annuler{" "}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default Riviews;
