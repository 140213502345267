import axios from "axios"
export const linkImage = 'https://api.australiecars.com/'

const axiosClient = axios.create({
    baseURL: "https://api.australiecars.com/",
})

axiosClient.interceptors.request.use((config) => {   
    const token = localStorage.getItem("ACCESS_TOKEN");   
    config.headers.Authorization = `Bearer ${token}`;   
    return config; }); 

export default axiosClient
