import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import axiosClient from "../../axios-client";
import { Card, CardBody, Col, Row, FormGroup, Form } from "reactstrap";

import { useNavigate } from 'react-router-dom';

const AddManager = (props) => {
  const navigate = useNavigate();

  //modal
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    gender: "Femme",
    tel: "",
    image: null,
  });
  //error and validation
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  //validation for email
  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };
  //validation for password
  const isValidPassword = (password) => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    return passwordPattern.test(password);
  };

  //show password
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  //form submit
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate form data before submission
    if (!formData.firstName || formData.firstName.length > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "First name is required and must not exceed 40 characters.",
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "",
      }));
    }

    if (!formData.lastName || formData.lastName.length > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "Last name is required and must not exceed 40 characters.",
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        lastName: "",
      }));
    }
    //validation for email
    if (!formData.email || !isValidEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address.",
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    //validaion for password
    if (!formData.password || !isValidPassword(formData.password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a digit, and a special character.",
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
    const managerData = new FormData();

    managerData.append("first_name", formData.firstName);
    managerData.append("last_name", formData.lastName);
    managerData.append("email", formData.email);
    managerData.append("password", formData.password);
    managerData.append("role", formData.role);
    managerData.append("tel", formData.tel);
    managerData.append("gender", formData.gender);
    if (formData.image) {
      managerData.append("image", formData.image);
    }

    try {
      console.log("Request Data:", managerData); // Log the request data before sending
      const response = await axiosClient.post("/managers/", managerData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        // User created successfully
        console.log("Manager created successfully");
        setShowSuccessAlert(true); // Show the success alert
        navigate('/listManagers');
        // Redirect or perform other actions
      } else {
        console.error("Failed to add manager:", response.data);
      }

      if (response.etat === 201) {
        // User created successfully
        setShowSuccessAlert(true); // Show the success alert

        navigate('/listManagers');

        // Redirect or perform other actions
      } else {
        console.error("Failed to add manager:", response.data);
      }

      console.log("Role before update:", formData.role);
      console.log("gender before update:", formData.gender);
    } catch (error) {
      console.error("Error adding manager:", error);
    }
  };
  //handle image
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setFormData({
      ...formData,
      image: imageFile,
    });
  };

  const handleResetUser = () => {
    navigate('/listManagers');
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add admins </title>
      </MetaTags>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Ajouter un admnistrateur</h4>
              <Row>
                <Col lg={6}>
                  <div className="mt-4">
                    <Form onSubmit={handleFormSubmit}>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3 ">
                            <label className="form-label" htmlFor="firstName">
                              Prénom
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              id="firstName"
                              value={formData.firstName}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  firstName: e.target.value,
                                });
                                if (
                                  !e.target.value ||
                                  e.target.value.length > 40
                                ) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    firstName:
                                      "First name is required and must not exceed 40 characters.",
                                  }));
                                } else {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    firstName: "",
                                  }));
                                }
                              }}
                            />
                            {errors.firstName && (
                              <div className="invalid-feedback">
                                {errors.firstName}
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="lastName">
                              Nom
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.lastName ? "is-invalid" : ""
                              }`}
                              id="lastName"
                              value={formData.lastName}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  lastName: e.target.value,
                                });
                                if (
                                  !e.target.value ||
                                  e.target.value.length > 40
                                ) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    lastName:
                                      "Last name is required and must not exceed 40 characters.",
                                  }));
                                } else {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    lastName: "",
                                  }));
                                }
                              }}
                            />
                            {errors.lastName && (
                              <div className="invalid-feedback">
                                {errors.lastName}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="email">
                              Email
                            </label>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              id="email"
                              value={formData.email}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  email: e.target.value,
                                });
                                if (
                                  !e.target.value ||
                                  !isValidEmail(e.target.value)
                                ) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    email:
                                      "Please enter a valid email address.",
                                  }));
                                } else {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    email: "",
                                  }));
                                }
                              }}
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="password">
                              Mot de passe
                            </label>
                            <div className="input-group">
                              <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${
                                  errors.password ? "is-invalid" : ""
                                }`}
                                id="password"
                                value={formData.password}
                                onChange={(e) => {
                                  const newPassword = e.target.value;
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    password: newPassword,
                                  }));
                                  if (
                                    !newPassword ||
                                    !isValidPassword(newPassword)
                                  ) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      password:
                                        "Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a digit, and a special character.",
                                    }));
                                  } else {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      password: "", // Clear the error when password is valid
                                    }));
                                  }
                                }}
                              />

                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={handlePasswordToggle}
                              >
                                {showPassword ? (
                                  <i className="fa fa-eye-slash"></i>
                                ) : (
                                  <i className="fa fa-eye"></i>
                                )}
                              </button>
                              {errors.password && (
                                <div className="invalid-feedback">
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">Rôle</label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            value={formData.role}
                            onChange={(e) => {
                              const selectedRole = e.target.value;
                              setFormData((prevData) => ({
                                ...prevData,
                                role: selectedRole,
                              }));
                              console.log("Selected Role:", selectedRole); // Add this line for debugging
                            }}
                          >
                            <option value="">Choisir un rôle</option>
                            <option value="SUPERADMIN">Super Admin</option>
                            <option value="ADMIN">Admin</option>
                          </select>
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Gender
                        </label>
                        <div className="col-md-10">
                          <select
                            className="form-control"
                            value={formData.gender}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                gender: e.target.value,
                              })
                            }
                          >
                            <option value="Femme">Femme</option>
                            <option value="Homme">Homme</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">Image</label>
                        <div className="col-md-10">
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                        </div>
                      </Row>
                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn  w-md"
                          style={{ backgroundColor: "#00367F", color: "white" }} // Change the background color and text color here
                        >
                          Ajouter
                        </button>{" "}
                        <button
                          type="submit"
                          className="btn  w-md"
                          onClick={handleResetUser}
                          style={{ backgroundColor: "#00BFB1", color: "white" }} // Change the background color and text color here
                        >
                          Annuler
                        </button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showSuccessAlert && (
        <div className="alert alert-success">
          Administrateur ajouté avec succès !
          <button
            type="button"
            className="close"
            onClick={() => setShowSuccessAlert(false)}
          >
            &times;
          </button>
        </div>
      )}
    </React.Fragment>
  );
};
export default AddManager;
