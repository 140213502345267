import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import axiosClient from "../../axios-client";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const UpdateManager = (props) => {
  const navigate = useNavigate();

  //modal
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { id } = useParams();
  const [editManager, setEditManager] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "ADMIN",
    gender: "Femme",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [editedImage, setEditedImage] = useState(null); // Separate state for edited image

  useEffect(() => {
    axiosClient
      .get(`/managers/${id}/`)
      .then((response) => {
        const { role, ...managerData } = response.data;
        setEditManager({
          ...managerData,
          role: role || "ADMIN", // Set a default value if "role" is null or undefined
        });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
    setEditedImage(editManager.image);
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const newValue =
      type === "checkbox" ? checked : type === "file" ? files[0] : value;

    setEditManager({ ...editManager, [name]: newValue });
  };

  const handleUpdateManager = async () => {
    try {
      const formData = new FormData();
      formData.append("first_name", editManager.first_name);
      formData.append("last_name", editManager.last_name);
      formData.append("email", editManager.email);
      formData.append("password", editManager.password);
      formData.append("role", editManager.role);
      formData.append("gender", editManager.gender);

      if (editedImage) {
        formData.append("image", editedImage, editedImage.name);
      }

      const response = await axiosClient.put(`/managers/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Check for a successful status code (e.g., 200 or 201)
      if (response.status === 200 || response.status === 201) {
        setShowSuccessModal(true);
        navigate("/listManagers"); // Redirect using history.push
      } else {
        console.error("Failed to update manager:", response.data);
      }
    } catch (error) {
      console.error("Error updating manager:", error);
    }
  };

  const handleResetManager = () => {
    navigate("/listManagers");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>update Managers </title>
      </MetaTags>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Modifier un admnistrateur</h4>
              <Row>
                <Col lg={5}>
                  <div className="mt-4">
                    <Row>
                      <Col md={100}>
                        <div>
                          <img
                            src={imagePreview || editManager.image}
                            alt={`Avatar of ${editManager.first_name} ${editManager.last_name}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div>
                          <label className="form-label" htmlFor="firstName">
                            {" "}
                            Prénom
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="first_name"
                            value={editManager.first_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="lastName">
                            {" "}
                            Nom
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="last_name"
                            value={editManager.last_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                          {" "}
                          Email
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          value={editManager.email}
                          onChange={handleInputChange}
                        />{" "}
                      </div>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div>
                          <label className="form-label" htmlFor="firstName">
                            {" "}
                            Rôle
                          </label>
                          <div className="col-md-10">
                            <select
                              className="form-control"
                              value={editManager.role}
                              onChange={handleInputChange}
                              name="role"
                            >
                              <option value="ADMIN">Admin</option>
                              <option value="SUPERADMIN">SUPERADMIN</option>
                            </select>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="lastName">
                            {" "}
                            Gender
                          </label>
                          <div className="col-md-10">
                            <select
                              className="form-control"
                              value={editManager.gender}
                              onChange={handleInputChange}
                              name="gender"
                            >
                              <option value="Femme">Femme</option>
                              <option value="Homme">Homme</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary w-md"
                        onClick={handleUpdateManager}
                        style={{ backgroundColor: "#00367F", color: "white" }}
                      >
                        Modifier
                      </button>{" "}
                      <button
                        type="submit"
                        className="btn  w-md"
                        onClick={handleResetManager}
                        style={{ backgroundColor: "#00BFB1", color: "white" }}
                      >
                        Annuler
                      </button>
                    </div>
                    {/* modal content */}
                    <Modal
                      isOpen={showSuccessModal}
                      toggle={() => setShowSuccessModal(false)}
                    >
                      <ModalHeader toggle={() => setShowSuccessModal(false)}>
                        Success
                      </ModalHeader>
                      <ModalBody>Manager updated successfully!</ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-primary"
                          onClick={() => setShowSuccessModal(false)}
                        >
                          Close
                        </button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default UpdateManager;
