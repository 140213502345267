import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap"; // Import Modal components
import axiosClient from "../../axios-client";

const BookingEnligne = (props) => {

  const [modal_standard, setmodal_standard] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isModalClientOpen, setIsModalClientOpen] = useState(false);
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [bookingToArchive, setBookingToArchive] = useState(null);
  const [archiveConfirmationOpen, setArchiveConfirmationOpen] = useState(false);
  const [showAlertA, setShowAlertA] = useState(false);
  const [bookingToRepayement, setBookingToRepayement] = useState(null);
  const [repayementConfirmationOpen, setRepayementConfirmationOpen] = useState(false);
  const [showAlertR, setShowAlertR] = useState(false);

  const showSuccessAlertA = () => {
    setShowAlertA(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertA(false);
    }, 5000);
  };

  const showSuccessAlertR = () => {
    setShowAlertR(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertR(false);
    }, 5000);
  };

  const renderActions = (booking) => (
    <div>
      <button
        className="btn btn-sm btn-info mr-2"
        onClick={() => openDetailsModal(booking.id)}
      >
        <i className="ion ion-md-list-box mr-1"></i> Plus de details
      </button>{" "}
      <button
        className="btn btn-sm btn-danger mr-2"
        onClick={() => {
          setBookingToArchive(booking.id);
          setArchiveConfirmationOpen(true);
        }}
      >
        <i className="ion ion-md-archive mr-1"></i> Archiver
      </button>{" "}
      <button
        className="btn btn-sm btn-warning mr-2"
        onClick={() => {
          setBookingToRepayement(booking.id);
          setRepayementConfirmationOpen(true);
        }}
      >
        <i className="ion ion-ios-repeat mr-1"></i> Rembourser
      </button>
    </div>
  );

  const [bookingData, setBookingData] = useState({
    columns: [
      {
        label: "Code",
        field: "code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Voiture",
        field: "matricule_car",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date réservation",
        field: "date_book",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date depart",
        field: "start_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date retoure",
        field: "end_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "CIN / PASSEPORT",
        field: "cin_passport",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remboursement",
        field: "repayment",
        sort: "asc",
        width: 50,
      },
      {
        label: "Total Prix HT",
        field: "total_price",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 50,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const formatDateTime = (timestamp) => {
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        };
        return new Date(timestamp).toLocaleString("fr-FR", options);
      };
      try {
        const response = await axiosClient.get("/booking-en-ligne/");
        const bookingsData = response.data;

        const rows = await Promise.all(
          bookingsData.map(async (booking) => {
            const etatRepaiement = booking.repayment ? (
              <Badge
                className={"rounded-pill bg-success"}
                style={{ fontSize: 13 }}
              >
                Rembourser
              </Badge>
            ) : (
              <Badge
                className={"rounded-pill bg-danger"}
                style={{ fontSize: 13 }}
              >
                Non Rembourser
              </Badge>
            );
            return {
              code: booking.code,
              matricule_car: (
                <a
                  href="#"
                  onClick={() => {
                    openCarModal(booking.car);
                  }}
                >
                  {booking.matricule_car}
                </a>
              ),
              date_book: formatDateTime(booking.date_book),
              start_date: formatDateTime(booking.start_date),
              end_date: formatDateTime(booking.end_date),
              cin_passport: (
                <a
                  href="#"
                  onClick={() => {
                    openClientModal(booking.id);
                  }}
                >
                  {booking.cin_passport}
                </a>
              ),
              repayment:etatRepaiement,
              total_price: booking.total_price,
              actions: renderActions(booking),
            };
          })
        );

        setBookingData((prevData) => ({
          ...prevData,
          rows,
        }));
      } catch (error) {
        console.error("Error fetching booking :", error);
      }
    };

    fetchData();
  }, []);

  const formatDateTime = (timestamp) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    return new Date(timestamp).toLocaleString("fr-FR", options);
  };

  const openClientModal = (bookingId) => {

    // Fetch article details, including images
    axiosClient
      .get(`/booking/${bookingId}/`)
      .then((response) => {
        setSelectedBooking(response.data);
        setIsModalClientOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching article details:", error);
      });
  };

  const closeClientModal = () => {
    setIsModalClientOpen(false);
  };

  const openDetailsModal = (bookingId) => {

    // Fetch article details, including images
    axiosClient
      .get(`/booking/${bookingId}/`)
      .then((response) => {
        setSelectedBooking(response.data);
        setIsModalDetailsOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching article details:", error);
      });
  };

  const closeDetailsModal = () => {
    setIsModalDetailsOpen(false);
  };

  const openCarModal = (carId) => {

    // Fetch article details, including images
    axiosClient
      .get(`/cars/${carId}/`)
      .then((response) => {
        setSelectedCar(response.data);
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching car details:", error);
      });
  };

  const closeCarModal = () => {
    setIsModalOpen(false);
  };

  const handleArchiveBooking = async () => {
    try {
      await axiosClient.put(`/booking/update-archive/${bookingToArchive}/`);

      setBookingData((prevData) => ({
        ...prevData,
        rows: prevData.rows.filter((booking) => booking.id !== bookingToArchive),
      }));

      setArchiveConfirmationOpen(false);
      window.location.reload();
      showSuccessAlertA(); // Montrer l'alerte de suppression réussie
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleRepayementBooking = async () => {
    try {
      await axiosClient.put(`/booking/update-repaiement/${bookingToRepayement}/`);

      setBookingData((prevData) => ({
        ...prevData,
        rows: prevData.rows.filter((booking) => booking.id !== bookingToRepayement),
      }));

      setRepayementConfirmationOpen(false);
      window.location.reload();
      showSuccessAlertR(); // Montrer l'alerte de suppression réussie
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <React.Fragment>
      <div
        className="position-fixed"
        style={{ top: "10px", right: "10px", zIndex: "9999" }}
      >
        {showAlertA && (
          <UncontrolledAlert color="success">
            <strong>Archive réservation réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertR && (
          <UncontrolledAlert color="success">
            <strong>Remboursement réussi !!</strong>
          </UncontrolledAlert>
        )}
      </div>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle style={{ fontSize: 20 }} className="h4">
                Paiement sur place :{" "}
              </CardTitle>
              <MDBDataTable responsive striped bordered data={bookingData} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={isModalClientOpen} toggle={closeClientModal}>
        <ModalHeader
          toggle={closeClientModal}
          style={{ backgroundColor: "#008080", color: "white" }}
        >
          Information du client
        </ModalHeader>
        <ModalBody>
          {selectedBooking && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "100%" }}>
                <>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>CIN / PASSEPORT :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.cin_passport}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Prénom :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.first_name}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Nom :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.last_name}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Email :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.email}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Telephone :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.tel}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Ville :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.ville}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Code postale :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.codePostal}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Pays :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.pays}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Sexe :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.gender}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Date de naissance :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {formatDateTime(selectedBooking.birthday)}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={isModalDetailsOpen} toggle={closeDetailsModal}>
        <ModalHeader
          toggle={closeDetailsModal}
          style={{ backgroundColor: "#008080", color: "white" }}
        >
          Détails de la réservation
        </ModalHeader>
        <ModalBody>
          {selectedBooking && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: "100%" }}>
                <>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Code :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.code}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Lieu de départ :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.name_pickup_start}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Date :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {formatDateTime(selectedBooking.start_date)}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Heure :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.start_time}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Lieu de retoure :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.name_pickup_end}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Date :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {formatDateTime(selectedBooking.end_date)}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Heure :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.end_time}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Assurance :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.assurance ? "oui" : "Non"}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Conducteur supplémentaire :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.conducteur ? "oui" : "Non"}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Chaise enfant :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.childchair}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Rehausseur enfant :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.rehausseur}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        gap:45
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <span>Payer :</span>
                      </div>
                      <div style={{ width: 100 }}>
                        <span style={{ color: "black", fontWeight: "normal" }}>
                          {selectedBooking.payement ? "oui" : "Non"}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={isModalOpen} toggle={closeCarModal}>
        <ModalHeader
          toggle={closeCarModal}
          style={{ backgroundColor: "#008080", color: "white" }}
        >
          Détails de la voiture
        </ModalHeader>
        <ModalBody>
          {selectedCar && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: "1" }}>
                <h4>
                  Matricule :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.matricule}
                  </span>
                </h4>
                <p style={{ fontWeight: "bold", marginTop: 20 }}>
                  Marque :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.brand}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Model :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.model}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Année de sortie :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.year}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Couleur :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.color}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Nombre de passagers :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.passenger_number}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Climatisation :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.air_conditioning ? "oui" : "Non"}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Boite à vitesse :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.gearBox}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Carburant :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.fuel}
                  </span>
                </p>
              </div>
              <div
                style={{
                  height: 250,
                  width: 250,
                }}
              >
                <img
                  src={selectedCar.image}
                  alt={`Image`}
                  className="article-image"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }} // Ajustez les valeurs selon vos besoins
                />
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={archiveConfirmationOpen}
        toggle={() => setArchiveConfirmationOpen(false)}
      >
        <ModalHeader toggle={() => setArchiveConfirmationOpen(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>
          {bookingToArchive && (
            <div>
              <p>Est ce que vous étes sur de vouloir archiver cette réservation ?</p>
              <button className="btn btn-danger mr-2" onClick={handleArchiveBooking}>
                {" "}
                Archiver{" "}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setArchiveConfirmationOpen(false)}
              >
                {" "}
                Annuler{" "}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={repayementConfirmationOpen}
        toggle={() => setRepayementConfirmationOpen(false)}
      >
        <ModalHeader toggle={() => setRepayementConfirmationOpen(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>
          {bookingToRepayement && (
            <div>
              <p>Est ce que vous étes sur de vouloir rembourser pour cette réservation ?</p>
              <button className="btn btn-warning mr-2" onClick={handleRepayementBooking}>
                {" "}
                Rembourser{" "}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setRepayementConfirmationOpen(false)}
              >
                {" "}
                Annuler{" "}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>
      
    </React.Fragment>
  );
};

export default BookingEnligne;
