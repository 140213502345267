import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { Pagination, PaginationItem, PaginationLink, Input } from "reactstrap";
import axiosClient from "../../axios-client";
import oeil from "../../assets/images/oeil.png";

const CarsList = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [carsPerPage, setcarsPerPage] = useState(10);
  const [modal_standard, setmodal_standard] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertS, setShowAlertS] = useState(false);
  const [showAlertTA, setShowAlertTA] = useState(false);
  const [showAlertTM, setShowAlertTM] = useState(false);
  const [carMarque, setCarMarque] = useState("");
  const [carMatricule, setCarMatricule] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [carImage, setCarImage] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carAnne, setCarAnne] = useState();
  const [carCouleur, setCarCouleur] = useState("");
  const [carPassager, setCarPassager] = useState();
  const [carFuel, setCarFuel] = useState("ESSENCE");
  const [carGearBox, setCarGearBox] = useState("MANUELLE");
  const [carClimat, setCarClimat] = useState(false);
  const [carToDelete, setCarToDelete] = useState(null);
  const [carDispo, setCarDispo] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [changeConfirmationOpen, setChangeConfirmationOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [modal_standardTM, setmodal_standardTM] = useState(false);
  const [modal_standardTA, setmodal_standardTA] = useState(false);
  const [tarifMaxTree, setTarifMaxTree] = useState();
  const [tarifWeek, setTarifWeek] = useState();
  const [tarifMoreMonth, setTarifMoreMonth] = useState();
  const [tarifMoreTwoWeek, setTarifMoreTwoWeek] = useState();
  const [tarifTwoWeek, setTarifTwoWeek] = useState();
  const [tarifId, setTarifId] = useState();
  const [carId, setCarId] = useState();

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standardTA() {
    setmodal_standardTA(!modal_standardTA);
    removeBodyCss();
  }

  function tog_standardTM() {
    setmodal_standardTM(!modal_standardTM);
    removeBodyCss();
  }

  const showSuccessAlert = () => {
    setShowAlert(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const showSuccessAlertS = () => {
    setShowAlertS(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertS(false);
    }, 5000);
  };

  const showSuccessAlertTA = () => {
    setShowAlertTA(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertTA(false);
    }, 5000);
  };

  const showSuccessAlertTM = () => {
    setShowAlertTM(true);

    // Masquer l'alerte après 5 secondes
    setTimeout(() => {
      setShowAlertTM(false);
    }, 5000);
  };

  const fetchTarifByCar = async (id_car) => {
    try {
      const response = await axiosClient.get(`/tarif/car/${id_car}/`);

      // Assurez-vous que la réponse contient les données de poid (ajustez selon votre structure de données)
      const tarifsData = response.data;

      // Vérifiez si des données de poid existent
      const tarifsExists = tarifsData.length > 0;

      return {
        tarifsData,
        tarifsExists,
      };
    } catch (error) {
      console.error("Error fetching tarifs car:", error);
      return {
        tarifsData: null,
        tarifsExists: false,
      };
    }
  };

  const handelChangeTarif = async (carId) => {
    const result = await fetchTarifByCar(carId);
    const data = result.tarifsData;
    const exists = result.tarifsExists;
    if (exists) {
      setCarId(carId);
      setTarifId(data[0]?.id_tarif);
      setTarifMaxTree(data[0]?.price_tree_days);
      setTarifWeek(data[0]?.price_week);
      setTarifMoreMonth(data[0]?.price_more_month);
      setTarifMoreTwoWeek(data[0]?.price_more_two_week);
      setTarifTwoWeek(data[0]?.price_two_week);
      setmodal_standardTM(true);
    } else {
      setCarId(carId);
      setmodal_standardTA(true);
    }
  };

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);
      try {
        const response = await axiosClient.get("/cars/");
        setCars(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cars:", error);
        setLoading(false);
      }
    };

    fetchCars();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setCarImage(URL.createObjectURL(file));
  };

  const handleSaveCar = async () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("image", imageFile); // Ajouter le fichier d'icône s'il existe
    }
    formData.append("brand", carMarque);
    formData.append("model", carModel);
    formData.append("year", carAnne);
    formData.append("color", carCouleur);
    formData.append("matricule", carMatricule);
    formData.append("passenger_number", carPassager);
    formData.append("air_conditioning", carClimat);
    formData.append("gearBox", carGearBox);
    formData.append("fuel", carFuel);
    formData.append("disponible", true);

    try {
      const response = await axiosClient.post("/cars/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Mettre à jour l'état local des voitures avec la nouvelle voiture ajoutée
      setCars((prevCars) => [...prevCars, response.data]);

      tog_standard();
      showSuccessAlert();
    } catch (error) {
      console.error("Error saving category:", error);
    }
  };

  const handleDeleteCar = async () => {
    try {
      await axiosClient.delete(`/cars/${carToDelete}/`);

      // Mettez à jour localement l'état des voitures après la suppression
      setCars((prevCars) =>
        prevCars.filter((car) => car.id_car !== carToDelete)
      );

      setDeleteConfirmationOpen(false);
      setCarToDelete(null);
      showSuccessAlertS(); // Montrer l'alerte de suppression réussie
    } catch (error) {
      console.error("Error deleting car:", error);
    }
  };

  const handelChangeDispo = async (id) => {
    try {
      await axiosClient.put(`/car/update-dispo-car/${id}/`);

      // Mettez à jour localement l'état de la voiture après la modification
      setCars((prevCars) =>
        prevCars.map((car) =>
          car.id_car === id
            ? {
                ...car,
                disponible: !car.disponible,
              }
            : car
        )
      );
    } catch (error) {
      console.error("Error change dispo car:", error);
    }
  };

  const openCarModal = (carId) => {

    // Fetch article details, including images
    axiosClient
      .get(`/cars/${carId}/`)
      .then((response) => {
        setSelectedCar(response.data);
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching car details:", error);
      });
  };

  const closeCarModal = () => {
    setIsModalOpen(false);
  };

  const handleAjoutTarifs = async () => {
    const formData = new FormData();
    formData.append("car", carId);
    formData.append("price_more_month", tarifMoreMonth);
    formData.append("price_week", tarifWeek);
    formData.append("price_tree_days", tarifMaxTree);
    formData.append("price_more_two_week", tarifMoreTwoWeek);
    formData.append("price_two_week", tarifTwoWeek);

    try {
      const response = await axiosClient.post("/tarifs/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      tog_standardTA();
      showSuccessAlertTA();
    } catch (error) {
      console.error("Error saving tarifs :", error);
    }
  };

  const handleModifTarifs = async () => {
    const formData = new FormData();
    formData.append("car", carId);
    formData.append("price_more_month", tarifMoreMonth);
    formData.append("price_week", tarifWeek);
    formData.append("price_tree_days", tarifMaxTree);
    formData.append("price_more_two_week", tarifMoreTwoWeek);
    formData.append("price_two_week", tarifTwoWeek);

    try {
      const response = await axiosClient.put(`/tarifs/${tarifId}/`, formData);

      tog_standardTM();
      showSuccessAlertTM();
    } catch (error) {
      console.error("Error update tarifs :", error);
    }
  };

  const resetCarId = () => {
    setTarifId("");
    setTarifMaxTree();
    setTarifWeek();
    setTarifMoreMonth();
    setTarifMoreTwoWeek();
    setTarifTwoWeek();
  };

  const resetForm = () => {
    setCarMarque("");
    setCarMatricule("");
    setCarModel("");
    setCarImage("");
    setCarAnne("");
    setCarCouleur("");
    setCarPassager("");
    setCarFuel("ESSENCE");
    setCarGearBox("MANUELLE");
    setCarClimat(false);
  };

  // Pagination
  const indexOfLastUser = currentPage * carsPerPage;
  const indexOfFirstUser = indexOfLastUser - carsPerPage;
  const currentCars = cars.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePageSizeChange = (e) => {
    setcarsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Retour à la première page lors du changement de la taille de la page
  };

  return (
    <div>
      <div
        className="position-fixed"
        style={{ top: "10px", right: "10px", zIndex: "9999" }}
      >
        {showAlert && (
          <UncontrolledAlert color="success">
            <strong>Ajout voiture réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertS && (
          <UncontrolledAlert color="success">
            <strong>Suppresion de la voiture réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertTA && (
          <UncontrolledAlert color="success">
            <strong>Enregistrement des tarifs réussi !!</strong>
          </UncontrolledAlert>
        )}
        {showAlertTM && (
          <UncontrolledAlert color="success">
            <strong>Modifications des tarifs réussi !!</strong>
          </UncontrolledAlert>
        )}
      </div>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle style={{ fontSize: 20 }} className="h4">
                Liste des voitures
              </CardTitle>
              <div className="text-right">
                <button
                  type="button"
                  onClick={() => tog_standard()}
                  className="btn btn-primary waves-effect waves-light mr-3"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Ajouter nouvelle voiture
                </button>
              </div>
              <div className="table-responsive">
                {/* Sélecteur de taille de page */}
                <div className="mb-3 col-sm-12 col-md-4 col-lg-3 p-0 relative">
                  <label htmlFor="pageSizeDropdown" className="mr-2">
                    Taille de page :
                  </label>
                  <Input
                    style={{
                      width: "70px",
                    }}
                    type="select"
                    id="pageSizeDropdown"
                    value={carsPerPage}
                    onChange={handlePageSizeChange}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Input>
                </div>
                {loading ? (
                  <div className="spinner-container d-flex justify-content-center items-center ">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ width: 30, height: 30 }}
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <Table>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Matricule</th>
                        <th>Année de model</th>
                        <th>Model</th>
                        <th>Disponible</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentCars.length === 0 ? (
                        <tr>
                          <td colSpan="7">Aucune voitures n'est ajouter !!</td>
                        </tr>
                      ) : (
                        currentCars.map((car) => (
                          <tr key={car.id}>
                            <td>
                              {car.image ? (
                                <img
                                  src={car.image}
                                  alt="Car image"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              ) : null}
                            </td>
                            <td>{car.matricule}</td>
                            <td>{car.year}</td>
                            <td>{car.model}</td>
                            <td>
                              <Badge
                                key={`badge-${car.id_car}`} // Ajoutez une clé unique ici
                                className={`rounded-pill ${
                                  car.disponible ? "bg-success" : "bg-danger"
                                }`}
                                style={{ fontSize: 13, cursor: "pointer" }}
                                onClick={() => {
                                  handelChangeDispo(car.id_car);
                                }}
                              >
                                {car.disponible ? "disponible" : "louer"}
                              </Badge>
                            </td>
                            <td>
                              <button
                                className="btn btn-sm mr-2"
                                onClick={() => {
                                  openCarModal(car.id_car);
                                }}
                                style={{
                                  backgroundColor: "#00367F",
                                  color: "white",
                                }}
                              >
                                <img
                                  src={oeil}
                                  alt="visualiser"
                                  style={{ width: "20px", height: "20px" }}
                                />{" "}
                                Consulter
                              </button>{" "}
                              <button
                                className="btn btn-sm btn-danger mr-2"
                                onClick={() => {
                                  setCarToDelete(car.id_car);
                                  setDeleteConfirmationOpen(true);
                                }}
                              >
                                <i className="ion ion-ios-trash mr-1"></i>{" "}
                                Supprimer
                              </button>{" "}
                              <button
                                className="btn btn-sm btn-success mr-2"
                                onClick={() => handelChangeTarif(car.id_car)}
                              >
                                <i className="ion ion-logo-usd mr-1"></i>{" "}
                                Gestion tarifs
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                )}
                {cars.length > carsPerPage && (
                  <Pagination
                    className="pagination"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        onClick={() => paginate(currentPage - 1)}
                        previous
                      />
                    </PaginationItem>

                    {[...Array(Math.ceil(cars.length / carsPerPage))].map(
                      (_, index) => (
                        <PaginationItem
                          key={index}
                          active={index + 1 === currentPage}
                        >
                          <PaginationLink onClick={() => paginate(index + 1)}>
                            {index + 1}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    )}

                    <PaginationItem
                      disabled={
                        currentPage === Math.ceil(cars.length / carsPerPage)
                      }
                    >
                      <PaginationLink
                        onClick={() => paginate(currentPage + 1)}
                        next
                      />
                    </PaginationItem>
                  </Pagination>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal_standard} toggle={() => tog_standard()}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Ajouter une voiture
          </h5>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label htmlFor="categoryTitle">Marque</label>
              <input
                type="text"
                className="form-control"
                id="carMarque"
                value={carMarque}
                onChange={(e) => setCarMarque(e.target.value)}
                placeholder="Marque..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoryTitle">Matricule</label>
              <input
                type="text"
                className="form-control"
                id="carMatricule"
                value={carMatricule}
                onChange={(e) => setCarMatricule(e.target.value)}
                placeholder="Matricule..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoryIcon">Image</label>
              <input
                type="file"
                className="form-control"
                id="carImage"
                onChange={handleImageChange}
                placeholder="URL de l'image"
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoryTitle">Model</label>
              <input
                type="text"
                className="form-control"
                id="carModel"
                value={carModel}
                onChange={(e) => setCarModel(e.target.value)}
                placeholder="Model..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoryTitle">Année de model</label>
              <input
                type="text"
                className="form-control"
                id="carAnne"
                value={carAnne}
                onChange={(e) => setCarAnne(e.target.value)}
                placeholder="Année..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoryTitle">Couleur</label>
              <input
                type="text"
                className="form-control"
                id="carCouleur"
                value={carCouleur}
                onChange={(e) => setCarCouleur(e.target.value)}
                placeholder="Couleur..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoryTitle">Nombre de passagers</label>
              <input
                type="number"
                className="form-control"
                id="carPassager"
                value={carPassager}
                onChange={(e) => setCarPassager(e.target.value)}
                placeholder="Nombre de passagers..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="categoryTitle">Carburant</label>
              <select
                className="form-control"
                id="carFuel"
                value={carFuel}
                onChange={(e) => setCarFuel(e.target.value)}
                name="fuel"
              >
                <option value="ESSENCE">Essence</option>
                <option value="DIESEL">Diesel</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="categoryTitle">Boîte à vitesse</label>
              <select
                className="form-control"
                id="carGearBox"
                value={carGearBox}
                onChange={(e) => setCarGearBox(e.target.value)}
                name="carGearBox"
              >
                <option value="MANUELLE">Manuelle</option>
                <option value="AUTOMATIQUE">Automatique</option>
              </select>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="carClimat"
                checked={carClimat}
                onChange={(e) => {
                  setCarClimat(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="isSubCategory">
                Climatisation
              </label>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
              resetForm();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleSaveCar()}
          >
            Enregistrer
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={deleteConfirmationOpen}
        toggle={() => setDeleteConfirmationOpen(false)}
      >
        <ModalHeader toggle={() => setDeleteConfirmationOpen(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>
          {carToDelete && (
            <div>
              <p>Est ce que vous étes sur de supprimer cette voiture ?</p>
              <button className="btn btn-danger mr-2" onClick={handleDeleteCar}>
                {" "}
                Supprimer{" "}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setDeleteConfirmationOpen(false);
                  setCarToDelete(null);
                }}
              >
                {" "}
                Annuler{" "}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={isModalOpen} toggle={closeCarModal}>
        <ModalHeader
          toggle={closeCarModal}
          style={{ backgroundColor: "#008080", color: "white" }}
        >
          Détails de la voiture
        </ModalHeader>
        <ModalBody>
          {selectedCar && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: "1" }}>
                <h4>
                  Matricule :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.matricule}
                  </span>
                </h4>
                <p style={{ fontWeight: "bold", marginTop: 20 }}>
                  Marque :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.brand}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Model :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.model}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Année de sortie :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.year}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Couleur :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.color}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Nombre de passagers :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.passenger_number}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Climatisation :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.air_conditioning ? "oui" : "Non"}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Boite à vitesse :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.gearBox}
                  </span>
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Carburant :{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    {selectedCar.fuel}
                  </span>
                </p>
              </div>
              <div
                style={{
                  height: 250,
                  width: 250,
                }}
              >
                <img
                  src={selectedCar.image}
                  alt={`Image`}
                  className="article-image"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }} // Ajustez les valeurs selon vos besoins
                />
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal_standardTA}
        toggle={() => tog_standardTA()}
        onClosed={resetCarId}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Gestion des tarifs
          </h5>
        </div>
        <div className="modal-body">
          <form>
            <h5>
              Remplissez les tarifs par jour par rapport aux différents periodes
              :
            </h5>
            <div className="form-group mt-4">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période d'une journée à trois jours :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifMaxTree"
                value={tarifMaxTree}
                onChange={(e) => setTarifMaxTree(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de trois à une semaine :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifWeek"
                value={tarifWeek}
                onChange={(e) => setTarifWeek(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de deux semaine :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifTwoWeek"
                value={tarifTwoWeek}
                onChange={(e) => setTarifTwoWeek(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de plus de deux semaine :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifMoreTwoWeek"
                value={tarifMoreTwoWeek}
                onChange={(e) => setTarifMoreTwoWeek(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de un mois et plus :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifMoreMonth"
                value={tarifMoreMonth}
                onChange={(e) => setTarifMoreMonth(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standardTA();
              resetCarId();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleAjoutTarifs()}
          >
            Enregistrer
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modal_standardTM}
        toggle={() => tog_standardTM()}
        onClosed={resetCarId}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Gestion des tarifs
          </h5>
        </div>
        <div className="modal-body">
          <form>
            <h5>
              Modifier les tarifs par jour par rapport aux différentes périodes
              :
            </h5>
            <div className="form-group mt-4">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période d'une journée à trois jours :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifMaxTree"
                value={tarifMaxTree}
                onChange={(e) => setTarifMaxTree(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de trois à une semaine :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifWeek"
                value={tarifWeek}
                onChange={(e) => setTarifWeek(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de deux semaine :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifTwoWeek"
                value={tarifTwoWeek}
                onChange={(e) => setTarifTwoWeek(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de plus de deux semaine :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifMoreTwoWeek"
                value={tarifMoreTwoWeek}
                onChange={(e) => setTarifMoreTwoWeek(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
            <div className="form-group">
              <span style={{ color: "black", fontWeight: "normal" }}>
                Tarifs pour une période de un mois et plus :
              </span>
              <input
                type="number"
                className="form-control"
                id="tarifMoreMonth"
                value={tarifMoreMonth}
                onChange={(e) => setTarifMoreMonth(e.target.value)}
                placeholder="Tarif..."
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standardTM();
              resetCarId();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleModifTarifs()}
          >
            Modifier
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CarsList;
