import React, { useState } from "react";
import axiosClient from "../../axios-client";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import login from "../../assets/images/login.jpg";
import logo from "../../assets/images/logo.png";
import { useStateContext } from "../../context/ContextProvider";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [generalError, setGeneralError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setToken } = useStateContext();

  // Create a function to handle errors and set a timer to clear them
  const handleError = (errorMessage) => {
    setGeneralError(errorMessage);
    setEmailError(null);
    setPasswordError(null);

    setTimeout(() => {
      setGeneralError(null);
    }, 3000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      setEmailError(!email ? "L'e-mail est requis" : null);
      setPasswordError(!password ? "le mot de passe est requis" : null);
      setGeneralError(null);
      return;
    }

    try {
      const payload = {
        email,
        password,
      };
      const response = await axiosClient.post("/token/manager/", payload);
      const data = response.data;
      setToken(data.access);
      navigate('/dashboard');
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          handleError("Email n'existe pas");
        } else if (error.response.data.message === "Mot de passe incorrect") {
          handleError("Mot de passe incorrect");
        } else if (error.response.data.message === "Email n'existe pas") {
          handleError("Email n'existe pas");
        } else if (
          error.response.data.message === "votre compte est désactivé"
        ) {
          handleError("votre compte est désactivé");
        }
      } else {
        handleError("general", "An error occurred. Please try again later.");
      }
    }
  };

  return (
    <MDBContainer className="my-5">
      <MDBCard>
        <MDBRow className="g-0">
          <MDBCol md="6">
            <img
              src={login}
              alt="login form"
              className="rounded-start"
              style={{ maxWidth: "85%", position: "relative", bottom: "-30px" }}
            />
          </MDBCol>

          <MDBCol md="6">
            <MDBCardBody className="d-flex flex-column">
              <div className="d-flex flex-row mt-2 justify-content-center align-items-center">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo-image me-3"
                  style={{
                    width: "280px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />
              </div>

              <h5
                className="fw-normal my-4 pb-3"
                style={{ letterSpacing: "1px" }}
              >
                Connectez-vous pour continuer sur AustralieCars Admin
              </h5>

              <form onSubmit={handleSubmit}>
                <MDBInput
                  label="Adresse email"
                  id="email"
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && <div className="text-danger">{emailError}</div>}

                <MDBInput
                  label="Mot de passe"
                  id="password"
                  type="password"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passwordError && (
                  <div className="text-danger">{passwordError}</div>
                )}

                {generalError && (
                  <div className="text-danger">{generalError}</div>
                )}

                <button
                  style={{
                    backgroundColor: "#00bfb1",
                    padding: "0.5rem 0.5rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                    textAlign: "right",
                    color: "white",
                    fontSize: "16px",
                  }}
                  className="mb-4 px-5"
                  type="submit"
                >
                  Se connecter
                </button>
              </form>

              {/* <a className="small text-muted" href="#!">
                  Mot de passe oublié?
                </a> */}

              <div className="d-flex flex-row justify-content-start">
                {/* <a href="#!" className="small text-muted me-1">
                    Conditions d'utilisation
                  </a>
                  <a href="#!" className="small text-muted">
                    Politique de confidentialité
                  </a> */}
              </div>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
};

export default Login;
