import React from "react"
import { BrowserRouter as Route, Routes } from "react-router-dom"

import Dashboard from "./pages/Dashboard/dashboard"
import Login from "./pages/Authentification/Login"
import TestPage from "./pages/Authentification/TestPage"
import Profile from "./pages/Settings/profile"
import ListManager from "./pages/Managers/ListManager"
import AddManager from "./pages/Managers/AddManager"
import UpdateManager from "./pages/Managers/UpdateManager"
// import ListVoitures from "./pages/Cars/listVoitures"
import Temoignages from "./pages/Reviews/temoignages"
import PointRelais from "./pages/Pickup/pointRelais"
import BookingAll from "./pages/Booking/booking_all"
import BookingSurplace from "./pages/Booking/booking_surplace"
import BookingEnLigne from "./pages/Booking/booking_enligne"
import CarsList from "./pages/Cars/carsList"
import Contacts from "./pages/Contacts/contacts"

import AccessDeniedElement from "../src/pages/Autorisation/AccessDeniedelement"

import ProtectedRoute  from "./pages/Authentification/ProtectedRoute"

const routes = ({ role }) => {

  const isSuperAdmin = ['SUPERADMIN'].includes(role);
  const isAdmin = ['SUPERADMIN', 'ADMIN'].includes(role);



  return (
      <Routes>
        <ProtectedRoute path="/dashboard" element={isAdmin ? <Dashboard/> : <AccessDeniedElement />} canAccess={isAdmin} />
        <Route path="/" element={<Login/>} exact />
        <ProtectedRoute path="/test" element={<TestPage/>} exact />
        <ProtectedRoute path="/profile" element={isAdmin ? <Profile/> : <AccessDeniedElement />} canAccess={isAdmin} />
        <ProtectedRoute path="/listManagers" element={isSuperAdmin ? <ListManager/> : <AccessDeniedElement />} canAccess={isSuperAdmin}/>
        <ProtectedRoute path="/addmanager" element={isSuperAdmin ? <AddManager/> : <AccessDeniedElement />} canAccess={isSuperAdmin}/>
        <ProtectedRoute path="/updatemanager/:id" element={isSuperAdmin ? <UpdateManager/> : <AccessDeniedElement />} canAccess={isSuperAdmin}/>
        {/* <ProtectedRoute path="/listVoitures" element={isAdmin ? <ListVoitures/> : <AccessDeniedElement />} canAccess={isAdmin} /> */}
        <ProtectedRoute path="/temoignages" element={isAdmin ? <Temoignages/> : <AccessDeniedElement />} canAccess={isAdmin} />
        <ProtectedRoute path="/pointRelais" element={isAdmin ? <PointRelais/> : <AccessDeniedElement />} canAccess={isAdmin} />
        <ProtectedRoute path="/booking_all" element={isSuperAdmin ? <BookingAll/> : <AccessDeniedElement />} canAccess={isSuperAdmin}/>
        <ProtectedRoute path="/booking_surplace" element={isAdmin ? <BookingSurplace/> : <AccessDeniedElement />} canAccess={isAdmin} />
        <ProtectedRoute path="/booking_enligne" element={isAdmin ? <BookingEnLigne/> : <AccessDeniedElement />} canAccess={isAdmin} />
        <ProtectedRoute path="/carsList" element={isAdmin ? <CarsList/> : <AccessDeniedElement />} canAccess={isAdmin} />
        <ProtectedRoute path="/contacts" element={isAdmin ? <Contacts/> : <AccessDeniedElement />} canAccess={isAdmin} />
      </Routes>
  )
}

export default routes
